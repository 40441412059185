<template>
  <b-container class="h-100-d">
    <b-row class="h-100-d align-items-center justify-content-center">
      <b-col cols="12" md="5">
        <div class="login-card text-center shadow rounded py-5">
          <img
            class="mb-5 login-logo"
            src="../assets/images/d-dot-logo.svg"
            alt
          />
          <div class="mb-5">
            <p class="code">401</p>
            <p class="code-detail">Oops! Unauthorized</p>
          </div>
          <button
            @click="goToHomePage"
            class="btn rounded-pill bg-color-primary text-white"
          >
            Go go Chat Page
          </button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    goToHomePage() {
      window.location.href = `/chat?brandID=${this.$route.query.brandID}`;
    }
  }
};
</script>

<style scoped>
.login-logo {
  width: 50%;
  height: 100%;
}
.code {
  font-size: 3rem;
}

.code-detail {
  font-size: 1.5rem;
}
.h-100-d {
  height: 100dvh;
}
</style>
